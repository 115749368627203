<template>
    <div>
      
      <b-modal
          id="parent_child_venue"
          :no-close-on-backdrop="true"
          size="sm"
          :hide-footer="true"
          :hide-header="false"
          :centered="true"
          ref="parent_child_venue"
          title="Vendor Login"
        header-bg-variant="primary"
        header-text-variant="light"
          >
      <button type="button" class="close custom-close-button" data-dismiss="modal" @click="closeChild()">
                &times;
              </button>
              <div  class=" mt-3">
             <input type="text" class="form-control" v-model="vendor_id" placeholder="Enter Vendor ID">
              <center><b-button variant="primary" class="mt-3 d-flex align-items-center justify-content-center" @click="target_selectChild()"
                  ><i class="mdi mdi-magnify fs-4 "></i> Login </b-button
                  ></center>
            <span v-if="errormsg" class="text-danger">Please Fill the field</span>
              </div>
  
          </b-modal>
    </div>
  </template>
  
  <script>
    import axios from "axios";
   export default {
      data: () => ({
          parent_option:[],
          parent_list:false,
          parent_selected:'',
          vendor_id:'',
          errormsg:false
      }),
      components: {
         
      },
      methods: {
        showModal() {
          this.$refs['parent_child_venue'].show()
        },
        hideModal() {
          this.$refs['parent_child_venue'].hide()
        },
        createparent()
        {
          this.$router.push("/venues/add-parent-venue");
        },
         
        closeChild()
        {
          this.$router.push("/Manage_vendors");
        },
        createChild()
        {
          this.parent_list=true;
          this.parentVenueList();
        },
        parentVenueList() {
        axios.get("/api/parent_venue_listing").then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              for (var i = 0; i < resp.data.parent_venue_details.length; i++) {
                this.parent_option.push({
                  value: resp.data.parent_venue_details[i].parent_venue_id,
                  name: resp.data.parent_venue_details[i].venue_name,
                });
              }
            }
          }
        });
      },
      target_selectChild()
      {
        
        this.errormsg=false;
        if(this.vendor_id!="")
        {
          
            this.$cookies.set('vendor_id',this.vendor_id);
         // this.$router.push("/finance/search_logs/" + this.vendor_id);
        /*  axios.post("/admin/admin_vendor_login",{ vendor_id : this.vendor_id}).then((resp) => {
          if (resp.data.status == true) {  */
            window.open('https://vendortest.venuebook.in/login?cid='+ this.vendor_id, '_blank');
          /*  } //VUE_VENDOR_URL
        }); */
        
        }
        else
        {
          this.errormsg=true;
        }
         
          
      }
      },
      mounted() {
         this.showModal();
        // this.parentVenueList();
      }
    }
  </script>
  <style>
  .modal-header .close {
    display:none;
  }
  .custom-close-button
  {
    position: absolute;
      top: -39px;
      right: 18px;
      color: white;
  }
  </style>
  
    